import { Provider } from '@angular/core';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_LIST_CONFIG } from '@angular/material/list';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

/** Angular Material global providers. */
export const materialProviders: Provider[] = [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
  { provide: MAT_SELECT_CONFIG, useValue: { disableOptionCentering: true, hideSingleSelectionIndicator: true } },
  { provide: MAT_LIST_CONFIG, useValue: { hideSingleSelectionIndicator: true } },
  { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { hideSingleSelectionIndicator: true } },
];
